import React, { useState, useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Form, Container, Dropdown, Input, Checkbox, TextArea, Radio } from 'semantic-ui-react';
import { Loading } from '../../Components/UI/Loaders';
import { SubmitButton, CancelButton } from '../../Components/UI/Buttons';
import { PageTitle, SelectedItemLight } from '../../Components/UI/Content';
import { StateContext } from '../../Application/Context/StateContext';
import useCreateActivity from '../../Hooks/Activities/useCreateActivity';
import useCreateNotification from '../../Hooks/Notifications/useCreateNotification';
import SearchBusinessPartners from '../../Components/BusinessPartners/SearchBusinessPartners';
import SearchProjects from '../../Components/Projects/SearchProjects';
import useSearchContacts from '../../Hooks/Search/useSearchContacts';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { createExternalAttendeesJson } from '../../Utils/ActionArenaUtils';
import NewCalendarEvent from '../../Components/Outlook/OutlookEvent';
import useCreateCalendarEvent from '../../Hooks/CalendarEvents/useCreateCalendarEvent';

const NewActivity = (props) => {

    const { t } = useTranslation();
    const history = useHistory();
    const scollToTopRef = useRef();

    const [ activityRelation, setActivityRelation ] = useState("project");

    const [ selectedBusinessPartner, setSelectedBusinessPartner ] = useState(null); // Business partner object
    const [ selectedSupplier, setSelectedSupplier ] = useState(null); // Supplier partner object
    const [ selectedProject, setSelectedProject ] = useState(null); // Project object
    const [ searchContactQuery, setSearchContactQuery ] = useState('');
    const [ searchContactValue, setSearchContactValue ] = useState([]);

    const [ title, setTitle ] = useState("");
    const [ activityDate, setActivityDate ] = useState(new Date());
    const [ activityType, setActivityType ] = useState(1);
    const [ status, setStatus ] = useState(0);
    const [ businessUnit, setBusinessUnit ] = useState(1);   
    const [ internalAttendees, setInternalAttendees ] = useState("");
    const [ externalAttendees, setExternalAttendees ] = useState("");
    const [ objective, setObjective ] = useState("");
    const [ internalNotes, setInternalNotes ] = useState("");
    const [ externalNotes, setExternalNotes ] = useState("");

    const [ createNotificationIsSelected, setCreateNotificationIsSelected ] = useState(false);
    const [ notificationDate, setNotificationDate] = useState(new Date());
    const [ notificationDescription, setNotificationDescription] = useState("");

    /* Outlook event */
    const [ addToOutlook, setAddToOutlook ] = useState(false);
    const [ startDate, setStartDate ] = useState(() => {
        const d = new Date(notificationDate);
        d.setHours(d.getHours() + 1);
        d.setMinutes(0);
        d.setSeconds(0);
        d.setMilliseconds(0);
        return d;
    });
    const [ endDate, setEndDate ] = useState(() => {
        const d = new Date(notificationDate);
        d.setHours(d.getHours() + 2);
        d.setMinutes(0);
        d.setSeconds(0);
        d.setMilliseconds(0);
        return d;
    });
    const [ location, setLocation ] = useState("");

    const [ isLoading, setIsLoading ] = useState(false);
    const [ isValidSelectedCustomer, setIsValidSelectedCustomer ] = useState(true);
    const [ isValidSelectedSupplier, setIsValidSelectedSupplier ] = useState(true);
    const [ isValidSelectedProject, setIsValidSelectedProject ] = useState(true);

    const { createActivity } = useCreateActivity();
    const { createNotification } = useCreateNotification();
    const { contacts, searchContacts } = useSearchContacts();
    const { createCalendarEvent } = useCreateCalendarEvent();

    const { userProfile,statusCodes, activityTypes, businessUnits } = useContext(StateContext);

    let searchProjectsStatusOptions = statusCodes.map((status, index) => {
        return {
            "key": status.Id,
            "text": status.Name,
            "value": status.Id
        }
    });
    searchProjectsStatusOptions.unshift({ "key": 0, "text": t('general.all'), "value": 0 });

    // Save data on submit
    const onSubmit = async () => {
        try {

            if (activityRelation === 'customer' && !selectedBusinessPartner) return setIsValidSelectedCustomer(false);
            if (activityRelation === 'supplier' && !selectedSupplier) return setIsValidSelectedSupplier(false);
            if (activityRelation === 'project' && !selectedProject) return setIsValidSelectedProject(false);
            if (activityType && activityType === 5 && !selectedProject) return setIsValidSelectedProject(false);

            setIsLoading(true);

            const activityObj = {
                "title": title,
                "contactDate": activityDate ? activityDate.toISOString() : null,
                "type": activityType,
                "businessPartner": null,
                "businessUnit": businessUnit,
                "internalAttendee": internalAttendees,
                "externalAttendee": JSON.stringify(createExternalAttendeesJson(externalAttendees, searchContactValue, contacts)),
                "objective": objective,
                "externalNotes": externalNotes,
                "internalNotes": internalNotes,
                "salesPerson": userProfile && userProfile.Id ? userProfile.Id : null,
                "contact": null
            }

            // Add status only if activity type is status change"
            if (activityType && activityType === 5 && activityRelation === 'project' && selectedProject) {
                activityObj.projectNewStatus = status;
            }

            if (activityRelation === 'customer' && selectedBusinessPartner && selectedBusinessPartner.Id) {
                activityObj.businessPartner = parseInt(selectedBusinessPartner.Id);
            }

            if (activityRelation === 'supplier' && selectedSupplier && selectedSupplier.Id) {
                activityObj.businessPartner = parseInt(selectedSupplier.Id);
            }

            if (activityRelation === 'project' && selectedProject && selectedProject.Id) {
                activityObj.project = parseInt(selectedProject.Id);
            }

            let result = await createActivity(activityObj);

            if (result && result.status === 200) {

                let createdId = null;

                if (result && result.data && result.data.createdId) createdId = result.data.createdId;

                if (createdId && createNotificationIsSelected) {

                    const notificationObj = {
                        "name": "Activity notification",
                        "title": "Activity notification",
                        "activity": createdId,
                        "date": notificationDate ? notificationDate.toISOString() : null,
                        "description": notificationDescription,
                        "businessPartner": null,
                        "salesPerson": userProfile && userProfile.Id ? userProfile.Id : null
                    }

                    if (activityRelation === 'customer' && selectedBusinessPartner && selectedBusinessPartner.Id) {
                        notificationObj.businessPartner = parseInt(selectedBusinessPartner.Id);
                    }

                    if (activityRelation === 'supplier' && selectedSupplier && selectedSupplier.Id) {
                        notificationObj.businessPartner = parseInt(selectedSupplier.Id);
                    }

                    await createNotification(notificationObj);

                    if (addToOutlook) {
                
                        const outlookEvent = {
                            "subject": title.trim().toString(),
                            "body": {
                                "contentType": "HTML",
                                "content": notificationDescription.trim()
                            },
                            "start": {
                                "dateTime": startDate.toISOString(),
                                "timeZone": "FLE Standard Time"
                            },
                            "end": {
                                "dateTime": endDate.toISOString(),
                                "timeZone": "FLE Standard Time"
                            },
                            "location": {
                                "displayName": location.trim().toString()
                            }
                        }

                        await createCalendarEvent(outlookEvent);
                        // Do not block if event creation fails.
                        //if (res && res.status && res.status !== 200) return;

                    }

                }

                return history.goBack();

            }

            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        (async() => {
            scollToTopRef.current.scrollIntoView();
            await searchContacts();
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    useEffect(() => {
        if (selectedProject) {
            if (selectedProject.BusinessUnit) setBusinessUnit(selectedProject.BusinessUnit)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedProject]);

    // Sort contacts by selected businesspartner or project
    let businessPartnerIdfilter = 0;
    if (activityRelation && activityRelation === "customer" && selectedBusinessPartner) businessPartnerIdfilter = selectedBusinessPartner.Id;
    if (activityRelation && activityRelation === "supplier" && selectedSupplier) businessPartnerIdfilter = selectedSupplier.Id;
    if (activityRelation && activityRelation === "project" && selectedProject) businessPartnerIdfilter = selectedProject.BusinessPartner;

    // 1.Filter contacts by business partner of project selection
    const filteredBusinessPartnerContacts = contacts.filter(item => {
        return item.BusinessPartner === businessPartnerIdfilter;
    });

    // 2.Filter other contacts
    const filteredOtherContacts = contacts.filter(item => {
        return item.BusinessPartner !== businessPartnerIdfilter;
    });

    // 3. Combine arrays.
    let orderedContacts = [];
    if (filteredBusinessPartnerContacts.length > 0 || filteredOtherContacts.length > 0 ) {
        orderedContacts = [...filteredBusinessPartnerContacts, ...filteredOtherContacts];
    } else {
        orderedContacts = [...contacts];
    }

    return (
        <Container>
            <Form className="activity" onSubmit={(ev) => { ev.preventDefault(); onSubmit(); }} >
        
                <div ref={scollToTopRef}></div>

                <Loading active={ isLoading } text={t('general.loading')} />

                <PageTitle active title={t('activity.newActivity')} />
                
                {/* Title */}
                <Form.Field
                    required
                    label={t('general.title')}
                    //placeholder={t('general.title')}
                    maxLength="255"
                    control={Input}
                    value={title}
                    onChange={(ev, {value}) => setTitle(value) }
                    type="Text"
                />

                {/* Is activity related to customer or project? */}
                <Container className="fieldcontent">
                    <Form.Field
                        control={Radio}
                        label={t('activity.isRelatedToCustomer')}
                        placeholder={t('activity.isRelatedToCustomer')}
                        name='activityRelation'
                        checked={activityRelation === 'customer'}
                        onChange={(ev, {value}) => setActivityRelation('customer') }
                    />
                    <Form.Field
                        control={Radio}
                        label={t('activity.isRelatedToSupplier')}
                        placeholder={t('activity.isRelatedToSupplier')}
                        name='activityRelation'
                        checked={activityRelation === 'supplier'}
                        onChange={(ev, {value}) => setActivityRelation('supplier') }
                    />
                    <Form.Field
                        control={Radio}
                        label={t('activity.isRelatedToProject')}
                        placeholder={t('activity.isRelatedToProject')}
                        name='activityRelation'
                        checked={activityRelation === 'project'}
                        onChange={(ev, {value}) => setActivityRelation('project') }
                    />
                </Container>

                {/* Search customers if activity is related to business partner */}
                {activityRelation === 'customer' &&
                    <>
                        {/* Customer is NOT selected - Show searchbox */}
                        {!selectedBusinessPartner &&
                            <SearchBusinessPartners
                                title={t('businessPartner.searchCustomer')}
                                placeholder={t('businessPartner.searchCustomer')}
                                isLoading={isLoading}
                                setIsLoading={setIsLoading}
                                selectedBusinessPartner={selectedBusinessPartner}
                                setSelectedBusinessPartner={setSelectedBusinessPartner}
                                setIsValidSelectedBusinessPartner={setIsValidSelectedCustomer}
                                type="customer"
                            />
                        }
                        {/* Customer is selected - Show selected item */}
                        {selectedBusinessPartner &&
                            <SelectedItemLight
                                title={t('businessPartner.selectedCustomer')}
                                item={selectedBusinessPartner}
                                headers={[t('general.name'), t('businessPartner.area'), t('general.businessId')]}
                                fields={['Name', 'AreaName', 'AssociationNumber']}
                                required={activityRelation === 'customer'}
                                isValidSelectedItem={isValidSelectedCustomer}
                                onRemoveSelection={(ev) => {
                                    setSelectedBusinessPartner(null);
                                }}
                            />
                        }
                    </>
                }

                {/* Search suppliers if activity is related to supplier */}
                {activityRelation === 'supplier' &&
                    <>
                        {/* Supplier is NOT selected - Show searchbox */}
                        {!selectedSupplier &&
                            <SearchBusinessPartners
                                title={t('businessPartner.searchSupplier')}
                                placeholder={t('businessPartner.searchSupplier')}
                                isLoading={isLoading}
                                setIsLoading={setIsLoading}
                                selectedBusinessPartner={selectedSupplier}
                                setSelectedBusinessPartner={setSelectedSupplier}
                                setIsValidSelectedBusinessPartner={setIsValidSelectedSupplier}
                                type="supplier"
                            />
                        }
                        {/* Supplier is selected - Show selected item */}
                        {selectedSupplier &&
                            <SelectedItemLight
                                title={t('businessPartner.selectedSupplier')}
                                item={selectedSupplier}
                                headers={[t('general.name'), t('businessPartner.area'), t('general.businessId')]}
                                fields={['Name', 'AreaName', 'AssociationNumber']}
                                required={activityRelation === 'supplier'}
                                isValidSelectedItem={isValidSelectedSupplier}
                                onRemoveSelection={(ev) => {
                                    setSelectedSupplier(null);
                                }}
                            />
                        }
                    </>
                }

                {/* Search customers if activity is related to project*/}
                {activityRelation === 'project' &&
                    <>
                        {/* Project is NOT selected - Show searchbox */}
                        {!selectedProject &&
                            <>
                                <Form.Field label={t('project.searchProjects')} style={{ marginTop: '20px', marginBottom: '10px'}} />
                                <SearchProjects
                                    placeholder={t('project.searchProjects')}
                                    isLoading={isLoading}
                                    setIsLoading={setIsLoading}
                                    selectedProject={selectedProject}
                                    setSelectedProject={setSelectedProject}
                                    setIsValidSelectedProject={setIsValidSelectedProject}
                                />
                            </>
                        }
                        {/* Project is selected - Show selected item */}
                        {selectedProject &&
                            <SelectedItemLight
                                title={t('project.selectedProject')}
                                item={selectedProject}
                                headers={[t('general.title'), t('project.currentStatus'), t('general.businessPartner'), t('businessPartner.businessUnit')]}
                                fields={['Title', 'StatusName', 'BusinessPartnerName', 'BusinessUnitName']}
                                required={activityRelation === 'project'}
                                isValidSelectedItem={isValidSelectedProject}
                                onRemoveSelection={(ev) => {
                                    setSelectedProject(null);
                                }}
                            />
                        }
                    </>
                }

                {/* Project activity date */}
                <Container className="fieldcontent">
                    <Form.Field
                        control={DatePicker}
                        required
                        label={t('activity.projectActivityDate')}
                        selected={activityDate}
                        dateFormat={navigator.language === "fi-FI" ? "dd.MM.yyyy" : "yyyy/MM/dd"}
                        onChange={(date) => setActivityDate(date)}
                    />
                </Container>

                {/* Activity type */}
                <div className="fieldcontent">
                    <Form.Field
                        control={Dropdown}
                        required
                        placeholder={t('activity.activityType')}
                        label={t('activity.activityType')}
                        fluid
                        search
                        selection
                        options={activityTypes
                            .filter(type => {
                                return type.Id !== 7; // Do not show sales person change
                            })
                            .map(type => {
                            return {
                                key: type.Id,
                                value: type.Id,
                                text: type.Name
                            }
                        })}
                        value={activityType}
                        onChange={(ev, {value}) => setActivityType(value) }
                        error={activityType && activityType === 5 && !isValidSelectedProject && t('errors.projectMustBeSelected')}
                    />
                </div>

                {/* Project status (activity type 5 = prject status change) */}
                { activityRelation === 'project' && activityType && activityType === 5 && 
                    <div className="fieldcontent">
                        <Form.Field
                            //required
                            control={Dropdown}
                            placeholder={t('general.status')}
                            label={t('project.newStatus')} 
                            fluid
                            search
                            selection
                            options={statusCodes.filter(item => {
                                /* Dont allow user to set status 10 - 12 */
                                if (item.Id < 10) return true;
                                return false;
                            }).map((type, index) => {
                                return {
                                    key: index + 1,
                                    value: type.Id,
                                    text: type.Name
                                }
                            })}
                            value={status}
                            onChange={(ev, {value}) => {
                                const foundStatus = statusCodes.find(item => item.Id === value);
                                setStatus(value);
                                setTitle("Status change -> " + foundStatus.Name);
                            }}
                        />
                    </div>
                }

                {/* Business unit */}
                <div className="fieldcontent">
                    <Form.Field
                        disabled={(activityRelation === 'project' && businessUnit ? true : false)}
                        required={activityRelation !== 'project'}
                        label={t('businessPartner.businessUnit')}
                        fluid
                        search
                        selection
                        maxLength="255"
                        options={businessUnits.map(bu => {
                            return { key: bu.Id, value: bu.Id, text: bu.Name }
                        })}
                        control={Dropdown}
                        value={businessUnit}
                        onChange={(ev, {value}) => setBusinessUnit(value) }
                    />
                </div>
                
                {/* External notes */}
                <Form.Field
                    //required
                    label={t('activity.externalNotes')}
                    placeholder={t('activity.externalNotes')}
                    control={TextArea}
                    value={externalNotes}
                    rows={5}
                    onChange={(ev, {value}) => setExternalNotes(value) }
                    type="Text"
                />

                {/* Internal notes */}
                <Form.Field
                    //required
                    label={t('activity.internalNotes')}
                    placeholder={t('activity.internalNotes')}
                    control={TextArea}
                    value={internalNotes}
                    rows={15}
                    onChange={(ev, {value}) => setInternalNotes(value) }
                    type="Text"
                />

                {/* Objective */}
                <Form.Field
                    //required
                    label={t('activity.objective')}
                    placeholder={t('activity.objective')}
                    maxLength="255"
                    control={Input}
                    value={objective}
                    onChange={(ev, {value}) => setObjective(value) }
                    type="Text"
                />

                {/* External attendees */}
                <Form.Field label={t('activity.externalAttendees')} />
                <Dropdown
                    placeholder={t('activity.selectExternalAttendeesFromContacts')}
                    clearable
                    fluid
                    multiple
                    search
                    selection
                    options={orderedContacts.map(item => {
                        if (item && item.BusinessPartnerName) {
                            item.NameWithBP = item.Name + " [" + item.BusinessPartnerName + "]";
                        } else {
                            item.NameWithBP = item.Name;
                        }
                        return item;
                    }).map(item => {
                        return {
                            key: item.Id,
                            text: item.NameWithBP,
                            value: item.Id,
                        }
                    })}
                    onChange={(e, { searchQuery, value }) => {
                        setSearchContactQuery(searchQuery);
                        setSearchContactValue(value);
                    }}
                    onSearchChange={(e, { searchQuery, value }) => {
                        setSearchContactQuery(searchQuery);
                    }}
                    style={{ marginBottom: "1px" }}
                    searchQuery={searchContactQuery}
                    value={searchContactValue}
                />
                <Form.Field
                    maxLength="255"
                    control={Input}
                    value={externalAttendees}
                    onChange={(ev, {value}) => setExternalAttendees(value) }
                    type="Text"
                    placeholder={t('activity.externalAttendees')}
                />

                {/* Internal attendees */}
                <Form.Field
                    //required
                    label={t('activity.internalAttendees')}
                    placeholder={t('activity.internalAttendees')}
                    maxLength="255"
                    control={Input}
                    value={internalAttendees}
                    onChange={(ev, {value}) => setInternalAttendees(value) }
                    type="Text"
                />

                {/* Create notification? */}
                <Container className="fieldcontent">
                    <Form.Field
                        control={Checkbox}
                        label={t('activity.createNotification')}
                        placeholder={t('activity.createNotification')}
                        checked={createNotificationIsSelected}
                        onChange={(ev, {checked}) => setCreateNotificationIsSelected(checked) }
                    />
                </Container>

                {createNotificationIsSelected && 
                    <Container className="fieldcontent">

                        {/* Notification date */}
                        <Container className="fieldcontent">
                            <Form.Field label={t('notification.notificationDate')} style={{ marginBottom: '0px' }}/>
                            <DatePicker
                                required
                                label={t('notification.notificationDate')}
                                selected={notificationDate}
                                dateFormat={navigator.language === "fi-FI" ? "dd.MM.yyyy" : "yyyy/MM/dd"}
                                onChange={(date) => setNotificationDate(date)}
                            />
                        </Container>

                        {/* Notification description */}
                        <Container className="fieldcontent">
                            <Form.Field
                                //required
                                label={t('notification.description')}
                                placeholder={t('notification.description')}
                                control={TextArea}
                                value={notificationDescription}
                                onChange={(ev, {value}) => setNotificationDescription(value) }
                                type="Text"
                            />
                        </Container>

                        <NewCalendarEvent
                            addToOutlook={addToOutlook}
                            setAddToOutlook={setAddToOutlook}
                            title={title}
                            body={notificationDescription}
                            startDate={startDate}
                            setStartDate={setStartDate}
                            endDate={endDate}
                            setEndDate={setEndDate}
                            location={location}
                            setLocation={setLocation}
                        />

                    </Container>
                }

                {/* Buttons */}
                <Container className='button-container'>
                    <CancelButton onClick={(ev) => {ev.preventDefault(); history.goBack(); }} />
                    <SubmitButton type="submit" icon="save" className="bb-lblue" title={t('general.save')} />
                </Container>

            </Form>
        </Container>
    );

}

export default NewActivity;
