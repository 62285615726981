import React, { useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Input, TextArea, Container,Dropdown, Message } from 'semantic-ui-react';
import { Loading } from '../../Components/UI/Loaders';
import { SubmitButton, CancelButton } from '../../Components/UI/Buttons';
import { PageTitle, SelectedItemLight } from '../../Components/UI/Content';
import { StateContext } from '../../Application/Context/StateContext';
import useReadProject from '../../Hooks/Project/useReadProject';
import useReadBusinessPartner from '../../Hooks/BusinessPartners/useReadBusinessPartner';
import useUpdateProject from '../../Hooks/Project/useUpdateProject';
import SearchBusinessPartners from '../../Components/BusinessPartners/SearchBusinessPartners';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const EditProject = (props) => {

    const { t } = useTranslation();
    const { projectId } = useParams();
    const history = useHistory();
    const scollToTopRef = useRef();

    // General state
    const [ isLoading, setIsLoading ] = useState(true);
    const [ disableSave, setDisableSave ] = useState(false);
    const [ selectedBusinessPartner, setSelectedBusinessPartner ] = useState(null);
    const [ salesPersonName, setSalesPersonName ] = useState("");
    const [ title, setTitle ] = useState("");
    const [ description, setDescription ] = useState("");
    const [ businessUnit, setBusinessUnit ] = useState(0);
    const [ startDate, setStartDate ] = useState(new Date());
    const [ statusName, setStatusName ] = useState("");
    const [ application, setApplication ] = useState("");

    // Context and hooks
    const { userProfile, businessUnits } = useContext(StateContext);
    const { project, readProject } = useReadProject();
    const { businessPartner, readBusinessPartner } = useReadBusinessPartner();
    const { updateProject } = useUpdateProject();

    const userIsAdmin = userProfile && userProfile.IsAdmin ? true : false;
    const projectHasEnded = project && project.EndDate ? true : false; // If project has end date, project is ended and only admin can edit it!
    const projectIsNotActive = project && project.Status > 7 ? true : false; // if project status is greater than 7 (not active anymore) only admin can edit it!
    const disableEditing = projectHasEnded && projectIsNotActive && !userIsAdmin ? true : false;

    const [ isValidSelectedCustomer, setIsValidSelectedCustomer ] = useState(true);

    // Fetch data when use navigate to the page
    useEffect(() => {
        (async () => {
            scollToTopRef.current.scrollIntoView();
            setIsLoading(true);
            await readProject(projectId);
            //setIsLoading(false);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    // Set fetched data
    useEffect(() => {
        (async () => {
            if (project && project.Id) {
                if (project.SalesPersonName) setSalesPersonName(project.SalesPersonName);
                if (project.Title) setTitle(project.Title);
                if (project.Description) setDescription(project.Description);
                if (project.StartDate) setStartDate(new Date(project.StartDate));
                if (project.BusinessUnit) setBusinessUnit(project.BusinessUnit);
                if (project.Status) setStatusName(project.StatusName);
                if (project.BusinessPartner) await readBusinessPartner(project.BusinessPartner);
                if (project.Application) await setApplication(project.Application);
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[project]);

    // Set data
    useEffect(() => {
        if (businessPartner && businessPartner.Id) {
            setSelectedBusinessPartner(businessPartner);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [businessPartner]);

    // Stop loader
    useEffect(() => {
        if (project && project.Id 
            && businessPartner && businessPartner.Id) {
            setIsLoading(false);
        }       
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [project, businessUnit, businessPartner]);

    const onSubmit = async (ev) => {
        try {
            if (projectId) {

                if (!selectedBusinessPartner) return setIsValidSelectedCustomer(false);

                setDisableSave(true);
                setIsLoading(true);

                /* Create object containing the form data */
                const updatedProject = {
                    id: parseInt(projectId),
                    title: title.trim(),
                    description: description.trim(),
                    businessPartner: null,
                    businessUnit: parseInt(businessUnit),
                    application: application
                }

                if (selectedBusinessPartner && selectedBusinessPartner.Id) {
                    updatedProject.businessPartner = parseInt(selectedBusinessPartner.Id);
                }
                
                let startDataISO = startDate ? startDate.toISOString() : null;
                updatedProject.startDate = startDataISO;

                updatedProject.endDate = null;
                updatedProject.endReason = '';
                updatedProject.failedOrDeniedReason = '';

                const result = await updateProject(updatedProject)

                if (result && result.status && result.status === 200) {
                    return history.goBack();
                }

                setDisableSave(false);
                setIsLoading(false);
            }
        } catch (error) {
            setDisableSave(false);
            setIsLoading(false);
        }
    }

    return (
        <Form className="project" onSubmit={(ev) => { ev.preventDefault(); onSubmit(); }}>
            
            <div ref={scollToTopRef}></div>

            <Loading active={ isLoading } text={t('general.loading')} />

            <PageTitle active title={t('project.editProject')} />

            <Message warning visible={projectHasEnded || projectIsNotActive} content={t('warnings.projectIsNotActive')} />

            {/* Sales person */}
            <Container className='fieldcontent'>
                <Form.Field label={t('project.salesPerson')} />
                <p>{salesPersonName}</p>
            </Container>

            {/* Business unit */}
            <Form.Field label={t('businessPartner.businessUnit')} />
            <div className="fieldcontent">
                <Dropdown
                    required
                    placeholder={t('businessPartner.businessUnit')}
                    fluid
                    search
                    selection
                    value={businessUnit}
                    options={businessUnits.map(bu => {
                        return { key: bu.Id, value: bu.Id, text: bu.Name }
                    })}
                    onChange={(ev, {value}) => setBusinessUnit(value) }
                />
            </div>

            {/* Customer */}
            {!selectedBusinessPartner &&
                <SearchBusinessPartners
                    title={t('businessPartner.customer')}
                    placeholder={t('businessPartner.searchCustomer')}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    selectedBusinessPartner={selectedBusinessPartner}
                    setSelectedBusinessPartner={setSelectedBusinessPartner}
                    setIsValidSelectedBusinessPartner={setIsValidSelectedCustomer}
                    type="customer"
                />
            }
            {selectedBusinessPartner &&
                <SelectedItemLight
                    title={t('businessPartner.selectedCustomer')}
                    item={selectedBusinessPartner}
                    headers={[t('general.name'), t('businessPartner.area'), t('general.businessId')]}
                    fields={['Name', 'AreaName', 'AssociationNumber']}
                    required={true}
                    isValidSelectedItem={isValidSelectedCustomer}
                    onRemoveSelection={(ev) => {
                        setSelectedBusinessPartner(null);
                    }}
                />
            }

            {/* Title */}
            <Form.Field
                focus
                required
                label={t('general.title')}
                placeholder={t('general.title')}
                maxLength="255"
                control={Input}
                value={title}
                onChange={(ev, {value}) => setTitle(value) }
                type="Text"
            />

            {/* Application */}
            <Form.Field
                focus
                //required
                label={t('project.application')}
                placeholder={t('project.application')}
                maxLength="255"
                control={Input}
                value={application}
                onChange={(ev, {value}) => setApplication(value) }
                type="Text"
            />

            {/* Description */}
            <Form.Field
                //required
                label={t('general.description')}
                placeholder={t('general.description')}
                control={TextArea}
                value={description}
                onChange={(ev, {value}) => setDescription(value) }
                type="Text"
            />

            {/* Status */}
            <Container className='fieldcontent'>
                <Form.Field label={t('general.status')} />
                <p>{statusName}</p>
            </Container>
            
            {/* Start Date */}
            <Container className='fieldcontent'>
                <Form.Field label={t('project.startDate')} style={{ marginBottom: '0px' }}/>
                <DatePicker
                    required
                    label={t('project.startDate')}
                    selected={startDate}
                    dateFormat={navigator.language === "fi-FI" ? "dd.MM.yyyy" : "yyyy/MM/dd"}
                    onChange={(date) => setStartDate(date)}
                />
            </Container>

            {/* Errors */}
            <Message error visible={!projectId} content={t('errors.missingProjectId')} />
            <Message error visible={!userProfile.Id} content={t('errors.missingLoggedInUser')} />
            
            {/* Buttons */}
            <Container className='button-container'>
                <CancelButton onClick={(ev) => { ev.preventDefault(); history.goBack(); } }/>
                <SubmitButton
                    disabled={disableSave || (disableEditing && !userIsAdmin)}
                    className="bb-lblue"
                    icon="save"
                    title={t('general.save')}
                    type='submit'
                />
            </Container>

        </Form>
    );

}

export default EditProject;
