import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form , Dropdown, Checkbox, Container, Radio, Input, Message } from 'semantic-ui-react';
import { PageTitle } from '../../Components/UI/Content';
import PagedTable from '../../Components/UI/Lists';
import { NewButton, CancelButton } from '../../Components/UI/Buttons';
import { Loading } from '../../Components/UI/Loaders';
import { getSearchProjectStatusCodeFilters } from '../../Utils/ActionArenaUtils';
import { StateContext } from '../../Application/Context/StateContext';
import useSearchProjects from '../../Hooks/Search/useSearchProjects';
import useListUsers from '../../Hooks/User/useListUsers';
import { Accordion, Header, Grid, Icon, Segment } from 'semantic-ui-react'; 
import { SearchButton } from '../../Components/UI/Buttons';

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

/** Search items box component
 * @param {Function} setIsLoading - React setState function for updating loading state
 * @param {String} searchTerm - React state value which is added to the searchField
 * @param {Function} setSearchTerm - React setState function for updating searchField
 * @param {Function} searchProjects - Search project function. f.e. reach hook "searchProjects"
 * @param {Object} filters - additional components f.e. filter checkbox
 * @param {Array} statusCodes
 * @param {String} selectedStatusRadioGroupProjects
 * @param {Boolean} filterMyOnlyCheckboxProjects
 * @param {Boolean} filterBySalesPersonCheckboxProjects
 * @param {String} selectedSalesPersonProjects
 * @param {Array} searchByCategoryRadioProjects */
const SearchAllProjects = ({
    setIsLoading,
    searchTerm,
    setSearchTerm,
    searchProjects,
    filters,
    statusCodes,
    selectedStatusRadioGroupProjects,
    filterMyOnlyCheckboxProjects,
    filterBySalesPersonCheckboxProjects,
    selectedSalesPersonProjects,
    searchByCategoryRadioProjects
}) => {

   const { t } = useTranslation();
   const [ activeIndex, setActiveIndex ] = useState(0);

   /* Search items when user clicks search */
   const onClickSearch = async (searchTerm) => {

        setIsLoading(true);
        const statusCodeFilters = getSearchProjectStatusCodeFilters(statusCodes, selectedStatusRadioGroupProjects);
        const options = {
            own: filterMyOnlyCheckboxProjects,
            top: 200,
            status: statusCodeFilters,
            salesPerson: filterBySalesPersonCheckboxProjects ? selectedSalesPersonProjects : null
        }

        let result = await searchProjects(searchTerm, searchByCategoryRadioProjects, options);
        if (!result.requestCancelled) setIsLoading(false);
   }

   return (       
       <Container className={"fieldcontent"}>
           <Accordion>
               
               <Accordion.Title
                   active={activeIndex === 0}
                   index={0}
                   onClick={() => { setActiveIndex(activeIndex === 0 ? -1 : 0); }}
                   style={{paddingBottom: 0}}
               >
                   <Header as='h3' attached='top' className="search-items-header">
                       <Grid>
                           <Grid.Column width="8" textAlign="left">
                               <b>{t('general.search')}</b>
                           </Grid.Column>
                           <Grid.Column  width="8" textAlign="right">
                               <Icon name='dropdown' />
                           </Grid.Column>
                       </Grid>
                   </Header>
               </Accordion.Title>
               
               <Accordion.Content active={activeIndex === 0} style={{paddingTop: 0}}>
                   <Segment attached style={{ borderRadius: "0 0 5px 5px" }}>

                       <Input
                           fluid
                           icon='search'
                           placeholder={t('general.search')}
                           value={searchTerm}
                           onKeyPress={(ev, data) => {
                               if (ev.key === 'Enter') {
                                   ev.preventDefault();
                                   onClickSearch(searchTerm);
                               }
                           }}
                           onChange={(ev, {value}) => {
                               setSearchTerm(value);
                           }}
                           className="fieldcontent"
                       />

                       {/* Additional filters */}
                       <Container>
                           {filters}
                       </Container>

                       {/* Search items button */}
                       <Container textAlign="right">
                           <SearchButton className="bb-lblue" type="reset" onClick={(ev) => { onClickSearch(searchTerm); }} />
                       </Container>

                   </Segment>
               </Accordion.Content>

           </Accordion>
       </Container>
   );

}

const Projects = (props) => {

    const { t } = useTranslation();
    const history = useHistory();
    let query = useQuery();

    const { projects, setProjects, searchProjects } = useSearchProjects();
    const { users, listUsers } = useListUsers();
    const [ isLoading, setIsLoading ] = useState(true);
    const [ itemsPerPage, setItemsPerPage ] = useState(10);
    const [ filterProjectsListInput, setFilterProjectsListInput ]= useState('');

    const {

        // General
        statusCodes,

        // Search Projects
        searchTermProjects,
        setSearchTermProjects,

        selectedSalesPersonProjects,
        setSelectedSalesPersonProjects,

        filterMyOnlyCheckboxProjects,
        setFilterMyOnlyCheckboxProjects,
        filterBySalesPersonCheckboxProjects,
        setFilterBySalesPersonCheckboxProjects,
        searchByCategoryRadioProjects,
        setSearchByCategoryRadioProjects,
        selectedStatusRadioGroupProjects,
        setSelectedStatusRadioGroupProjects,   
        selectedStatusFiltersProjects,
        setSelectedStatusFiltersProjects        

    } = useContext(StateContext);

    /**  Get needed data on page load */
    useEffect(() => {
        (async() => {
            setIsLoading(true);
            await listUsers(true, true);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // On page load
    useEffect(() => {
        (async() => {

            // 1.Set default status code filters (selectedStatusFiltersProject)
            // 2.Load top 200 own active projects on page load

            setIsLoading(true);

            // Do not make api call unless all needed properties are found
            if (statusCodes && statusCodes.length) {

                // DEFAULT: Show only the status code which user should see!
                const filteredStatusCodes = statusCodes.filter(item => item.Id < 12);

                // DEFAULT: status with id 1-7 should be checked!
                let mappedStatusCodes = filteredStatusCodes.map(item => {
                    item.Checked = false;
                    if (item.Id < 8) item.Checked = true; // CASE ACTIVE
                    return item;
                });               

                // Default values to be used if no urls parameters are present
                // This happens when the user navigated from navigation or came back from single item
                let defaultSearchText = searchTermProjects;
                let defaultSearchCategory = searchByCategoryRadioProjects;
                let defaultFilterProjectsBySalesPerson = filterBySalesPersonCheckboxProjects;
                let defaultSelectedSalesPerson = selectedSalesPersonProjects;
                let defaultFilterMyProjectsOnly = filterMyOnlyCheckboxProjects;
                let defaultSearchStatusGroup = selectedStatusRadioGroupProjects;
                let defaultStatusCodeFilters = mappedStatusCodes;

                // IF query parameters are found. We want to modify the default query parameters using these.
                // This happens when the user navigated from Customers Statistics page "http://localhost:3000/businessPartners"
                let queryParameterSearchTerm = query.get("name") ? query.get("name") : null;    // Customer name
                let queryParameterOwn = query.get("own") ? query.get("own") : null;             // My own projects only
                let queryParameterActive = query.get("active") ? query.get("active") : null;    // Active projects or all projects (used only if status = 0)
                let queryParameterStatus = query.get("status") ? parseInt(query.get("status")) : null;    // Status code filter 1-12 (0 if no filter is set)

                // Change search text from query parameters
                if (queryParameterSearchTerm !== null) {
                    defaultSearchText = queryParameterSearchTerm;
                    defaultSearchCategory = 'customer';
                    defaultFilterProjectsBySalesPerson = false;
                    defaultSelectedSalesPerson = null;
                };

                // Change "My projects only" person checkbox from query parameters
                if (queryParameterOwn !== null) {
                    if (queryParameterOwn === "true") defaultFilterMyProjectsOnly = true;
                    if (queryParameterOwn === "false") defaultFilterMyProjectsOnly = false;
                }

                // Change "status filters" and "select search category" from query parameters
                // ONLY if status = 0
                if (queryParameterStatus !== null && queryParameterStatus === 0 && queryParameterActive !== null) {

                    console.log("queryParameterActive")
                    console.log(queryParameterActive)

                    if (queryParameterActive === "true") defaultSearchStatusGroup = 'active';
                    if (queryParameterActive === "false") defaultSearchStatusGroup = 'all';

                    const mappedStatusCodesByQyery = filteredStatusCodes.map(item => {
                        item.Checked = false;
                        if (queryParameterActive === "true" && item.Id < 8) item.Checked = true; // Case active status codes!
                        if (queryParameterActive === "false") item.Checked = true; // Case all status codes!
                        return item;
                    });

                    defaultStatusCodeFilters = mappedStatusCodesByQyery;

                }

                // Change status and activity filtering from query parameters
                // If query param status is 1 - 12, the set "select search category" to CUSTOM and filter status using the given status.
                if (queryParameterStatus !== null && queryParameterStatus > 0) {

                    defaultSearchStatusGroup = 'custom';

                    const mappedStatusCodesByQyery = filteredStatusCodes.map(item => {
                        item.Checked = false;
                        if (item.Id === queryParameterStatus) item.Checked = true;
                        return item;
                    });

                    defaultStatusCodeFilters = mappedStatusCodesByQyery;

                }

                // Create search options
                const options = {
                    own: defaultFilterMyProjectsOnly,
                    top: 200,
                    status: getSearchProjectStatusCodeFilters(defaultStatusCodeFilters, defaultSearchStatusGroup),
                    salesPerson: defaultSelectedSalesPerson
                };

                // Call search
                await searchProjects(defaultSearchText, defaultSearchCategory, options);
                
                // Save settings
                setSearchTermProjects(defaultSearchText);
                setSearchByCategoryRadioProjects(defaultSearchCategory);
                setFilterBySalesPersonCheckboxProjects(defaultFilterProjectsBySalesPerson);
                setSelectedSalesPersonProjects(defaultSelectedSalesPerson);
                setFilterMyOnlyCheckboxProjects(defaultFilterMyProjectsOnly);
                setSelectedStatusRadioGroupProjects(defaultSearchStatusGroup);
                setSelectedStatusFiltersProjects(defaultStatusCodeFilters);
                setIsLoading(false);

            }
            
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statusCodes]);

    return (
        <div className="projects">

            <PageTitle
                active
                title={t('project.projects')}
                button={
                    <NewButton
                        className="bb-lblue"
                        mini
                        onClick={(ev) => { ev.preventDefault(); history.push('/projects/new'); }}
                    />
                }
            />

            <Loading active={ isLoading } text={t('general.loading')} />

            <SearchAllProjects
                setIsLoading={setIsLoading}
                searchTerm={searchTermProjects}
                setSearchTerm={setSearchTermProjects}
                searchProjects={searchProjects}
                statusCodes={selectedStatusFiltersProjects}
                selectedStatusRadioGroupProjects={selectedStatusRadioGroupProjects}
                filterMyOnlyCheckboxProjects={filterMyOnlyCheckboxProjects}
                filterBySalesPersonCheckboxProjects={filterBySalesPersonCheckboxProjects}
                selectedSalesPersonProjects={selectedSalesPersonProjects}
                searchByCategoryRadioProjects={searchByCategoryRadioProjects}
                filters={
                    <>

                        {/* Filters */}
                        <Container className="fieldcontent">
                            <Form.Field
                                control={Checkbox}
                                label={t('project.myProjectsOnly')}
                                placeholder={t('project.myProjectsOnly')}
                                checked={filterMyOnlyCheckboxProjects}
                                onChange={(ev, {checked}) => {
                                    setProjects([]);
                                    setFilterBySalesPersonCheckboxProjects(false);
                                    setFilterMyOnlyCheckboxProjects(checked);
                                }}
                            />
                            <Form.Field
                                control={Checkbox}
                                label={t('project.filterProjectsBySalesPerson')}
                                placeholder={t('project.filterProjectsBySalesPerson')}
                                checked={filterBySalesPersonCheckboxProjects}
                                onChange={(ev, {checked}) => {
                                    setProjects([]);
                                    setFilterMyOnlyCheckboxProjects(false);
                                    setFilterBySalesPersonCheckboxProjects(checked);
                                }}
                            />
                        </Container> 

                        {/* Users */}
                        {filterBySalesPersonCheckboxProjects && 
                            <Container className="fieldcontent">
                                <Form.Field>
                                    <b>{t('general.salesPerson')}</b>
                                </Form.Field>
                                <Dropdown
                                    placeholder={t('general.salesPerson')}
                                    fluid
                                    search
                                    selection
                                    value={selectedSalesPersonProjects}
                                    options={users.map(item => {
                                        return {
                                            key: item.Id,
                                            value: item.Id,
                                            text: item.Name ? item.Name : "<Missing name>"
                                        }
                                    })}
                                    onChange={(ev, {value}) => {
                                        setSelectedSalesPersonProjects(value);
                                    }}
                                />
                            </Container>
                        }

                        {/* Select search by */}
                        <Container className="fieldcontent">
                            <Form.Field>
                                <b>{t('project.searchBy')}</b>
                            </Form.Field>
                            <Form.Field
                                control={Radio}
                                label={t('project.searchByProject')}
                                name='searchprojectsby'
                                placeholder={t('project.searchByProject')}
                                checked={searchByCategoryRadioProjects === 'project' ? true : false}
                                onChange={(ev, {checked}) => setSearchByCategoryRadioProjects('project')}
                            />
                            <Form.Field
                                control={Radio}
                                label={t('project.searchByCustomer')}
                                name='searchprojectsby'
                                placeholder={t('project.searchByCustomer')}
                                checked={searchByCategoryRadioProjects === 'customer' ? true : false}
                                onChange={(ev, {checked}) => setSearchByCategoryRadioProjects('customer')}
                            />
                            <Form.Field
                                control={Radio}
                                label={t('project.searchBySupplier')}
                                name='searchprojectsby'
                                placeholder={t('project.searchBySupplier')}
                                checked={searchByCategoryRadioProjects === 'supplier' ? true : false}
                                onChange={(ev, {checked}) => setSearchByCategoryRadioProjects('supplier') }
                            />
                            <Form.Field
                                control={Radio}
                                label={t('project.searchByProduct')}
                                name='searchprojectsby'
                                placeholder={t('project.searchByProduct')}
                                checked={searchByCategoryRadioProjects === 'product' ? true : false}
                                onChange={(ev, {checked}) => setSearchByCategoryRadioProjects('product') }
                            />
                        </Container>

                        {/* Status choices
                            - Set default everytime user select status set.
                            - All = all status codes. "Project removed by user" is filtered out
                            - Active = Planned,Started,Sample sent, Sample approved, Trial Agrees, Trial Approved, Commercial negotiations
                            - Passive = Project automatically passivated
                            - Closed = Project automatically closed
                            - Custom = user select. "Project removed by user" is filtered out
                        */}
                        <Container className="fieldcontent">
                            <Form.Field>
                                <b>{t('project.chooseStatusSet')}</b>
                            </Form.Field>
                            <Form.Field
                                control={Radio}
                                label={t('general.all')}
                                name='chooseStatusSet'
                                placeholder={t('general.all')}
                                checked={selectedStatusRadioGroupProjects === 'all' ? true : false}
                                onChange={(ev) => {
                                    setSelectedStatusRadioGroupProjects('all');
                                    const mapped = selectedStatusFiltersProjects.map(item => {
                                        item.Checked = false;
                                        if (item.Id < 12) item.Checked = true;
                                        return item;
                                    });
                                    setSelectedStatusFiltersProjects(mapped);
                                }}
                            />
                            <Form.Field
                                control={Radio}
                                label={t('general.active')}
                                name='chooseStatusSet'
                                placeholder={t('general.active')}
                                checked={selectedStatusRadioGroupProjects === 'active' ? true : false}
                                onChange={(ev) => {
                                    setSelectedStatusRadioGroupProjects('active');
                                    const mapped = selectedStatusFiltersProjects.map(item => {
                                        item.Checked = false;
                                        if (item.Id < 8) item.Checked = true;
                                        return item;
                                    });
                                    setSelectedStatusFiltersProjects(mapped);
                                }}
                            />
                            <Form.Field
                                control={Radio}
                                label={t('general.passive')}
                                name='chooseStatusSet'
                                placeholder={t('general.passive')}
                                checked={selectedStatusRadioGroupProjects === 'passive' ? true : false}
                                onChange={(ev) => {
                                    // This used to be status "8 Regular business", "9 Project failed", "10 Project automatically passivated".
                                    // Customer asked to changed this on 4.1.2023 to only status "Project automatically passivated".
                                    setSelectedStatusRadioGroupProjects('passive');
                                    const mapped = selectedStatusFiltersProjects.map(item => {
                                        item.Checked = false;
                                        //if (item.Id >= 8 && item.Id < 11) item.Checked = true;
                                        if (item.Id === 10) item.Checked = true;
                                        return item;
                                    });
                                    setSelectedStatusFiltersProjects(mapped);
                                }}
                            />
                            <Form.Field
                                control={Radio}
                                label={t('general.closed')}
                                name='chooseStatusSet'
                                placeholder={t('general.closed')}
                                checked={selectedStatusRadioGroupProjects === 'closed' ? true : false}
                                onChange={(ev) => {
                                    // This used to be status "11 Project automatically closed".
                                    // Customer asked 4.1.2023 to add also status "8 Regular business" and "9 Failed".
                                    setSelectedStatusRadioGroupProjects('closed');
                                    const mapped = selectedStatusFiltersProjects.map(item => {
                                        item.Checked = false;
                                        if (item.Id === 8 || item.Id === 9 || item.Id === 11) item.Checked = true;
                                        return item;
                                    });
                                    setSelectedStatusFiltersProjects(mapped);
                                }}
                            />
                            <Form.Field
                                control={Radio}
                                label={t('general.custom')}
                                name='chooseStatusSet'
                                placeholder={t('general.custom')}
                                checked={selectedStatusRadioGroupProjects === 'custom' ? true : false}
                                onChange={(ev) => {
                                    setSelectedStatusRadioGroupProjects('custom');
                                    const mapped = selectedStatusFiltersProjects.map(item => {
                                        item.Checked = false;
                                        if (item.Id < 12) item.Checked = true;
                                        return item;
                                    });
                                    setSelectedStatusFiltersProjects(mapped);

                                }}
                            />
                        </Container>

                        {/* Select status codes 
                            - Set selectedStatusRadioGroupProjects to custom everytime user select or unselect single status.
                            - change status filter selection */}
                        <Container className="fieldcontent">
                            <Form.Field>
                                <b>{t('status.statusFilters')}</b>
                            </Form.Field>
                            {selectedStatusFiltersProjects.map((code, index) => {
                                return <Form.Field
                                    key={index}
                                    control={Checkbox}
                                    label={code.Name}
                                    placeholder={code.Name}
                                    name='statusFilters'
                                    checked={code.Checked}
                                    onChange={(ev, {checked}) => {
                                        if (selectedStatusRadioGroupProjects !== 'custom') setSelectedStatusRadioGroupProjects('custom');
                                        const mapped = selectedStatusFiltersProjects.map(item => {
                                            if (item.Id === code.Id) item.Checked = checked;
                                            return item;
                                        })
                                        setSelectedStatusFiltersProjects(mapped);
                                    }}
                                />
                            })}
                        </Container>
                    </>
                }

            />

            <Message info size='mini' hidden={(filterBySalesPersonCheckboxProjects || filterMyOnlyCheckboxProjects) || (projects.length < 201 && !filterBySalesPersonCheckboxProjects && !filterMyOnlyCheckboxProjects)}>
                <p>{t('warnings.searchReturnedOver200Results')}</p>
            </Message>

            {/* Page size */}
            <Container className="fieldcontent" textAlign="right">
                <Form.Field>
                    <b>{t('general.pageSize')}</b>
                </Form.Field>
                <Dropdown
                    selection
                    value={itemsPerPage}
                    options={[
                        { key: 0, text: 5, value: 5 },
                        { key: 1, text: 10, value: 10 },
                        { key: 2, text: 25, value: 25 },
                        { key: 3, text: 50, value: 50 }
                    ]}
                    onChange={(ev, {value}) => setItemsPerPage(value) }
                />
            </Container>

            {/* Filter */}
            <Container className="fieldcontent">
                <Input
                    action={{
                        labelPosition: 'left',
                        icon: 'delete',
                        content: t('general.clear'),
                        style: {margin: 0},
                        onClick:(ev) => {
                            ev.preventDefault();
                            setFilterProjectsListInput('');
                        }
                    }}
                    onChange={(ev, {value} ) => {
                        ev.preventDefault();
                        setFilterProjectsListInput(value)
                    }}
                    fluid
                    //actionPosition='right'
                    placeholder={t('general.filter')}
                    value={filterProjectsListInput}
                />
            </Container>

            <Container className="fieldcontent">
                <PagedTable unstackable celled striped compact='very' pageSize={itemsPerPage}
                    header={[
                        t('general.title'),
                        t('general.salesPerson'),
                        t('businessPartner.customer'),
                        t('businessPartner.businessUnit'),
                        t('project.startDate'),
                        //t('project.passivatingDate'),
                        //t('project.closingDate'),
                        t('project.passivatedDate'),
                        t('project.endDate'),
                        t('general.status'),
                        t('general.action')
                    ]}
                    content={projects ? projects.filter(project => {
                        let matchTitle = project.Title ? project.Title.toLowerCase().indexOf(filterProjectsListInput.toLowerCase()) !== -1 : false;
                        let matchSalesPersonName = project && project.SalesPersonName ? project.SalesPersonName.toLowerCase().indexOf(filterProjectsListInput.toLowerCase()) !== -1 : false;
                        let matchBusinessPartnerName = project && project.BusinessPartnerName ? project.BusinessPartnerName.toLowerCase().indexOf(filterProjectsListInput.toLowerCase()) !== -1 : false;
                        let matchBusinessUnitName = project && project.BusinessUnitName ? project.BusinessUnitName.toLowerCase().indexOf(filterProjectsListInput.toLowerCase()) !== -1 : false;
                        let matchStartDate = project && project.StartDate ? new Date(project.StartDate).toLocaleDateString("fi-FI").indexOf(filterProjectsListInput.toLowerCase()) !== -1 : false;
                        let matchStatusName = project && project.StatusName ? project.StatusName.toLowerCase().indexOf(filterProjectsListInput.toLowerCase()) !== -1 : false;
                        if (matchTitle || matchSalesPersonName || matchBusinessPartnerName || matchBusinessUnitName || matchStartDate  || matchStatusName) return true;
                        return false;
                    }) : []}
                    resetPageOnContentChange={true}
                    contentKeys={[
                        {key: 'Title', target: '/projects/view/', type: 'link', targetKey: 'Id'},
                        {key: 'SalesPersonName' },
                        {key: 'BusinessPartnerName' },
                        {key: 'BusinessUnitName' },
                        {key: 'StartDate', type: 'date'},
                        //{key: 'PassivatingDate', type: 'date'},
                        //{key: 'ClosingDate', type: 'date'},
                        {key: 'PassivatedDate', type: 'date'},
                        {key: 'EndDate', type: 'date'},
                        {key: 'StatusName'},
                        {key: 'Title', type: 'linkWithTwoTargetsAndKeys', target1: '/projects/', targetKey1: 'Id', target2: '/activities/new', targetKey2:'', text: t('activity.newActivity')}
                    ]}
                />
            </Container>

            {/* Buttons */}
            <Container className="fieldcontent button-container">
                <CancelButton title={t('general.back')} onClick={(ev) => {ev.preventDefault(); history.goBack(); }} />
            </Container>

        </div>
    );

}

export default Projects;
