import React, { useState, createContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Loading } from '../../Components/UI/Loaders';
import useVerifyAuthenticatedUser from '../../Hooks/User/useVerifyAuthenticatedUser';
import useListStatusCodes from '../../Hooks/StatusCodes/useListStatusCodes';
import useListBusinessUnits from '../../Hooks/BusinessUnits/useListBusinessUnits';
import useListActivityTypes from '../../Hooks/ActivityTypes/useListActivityTypes';
import useReadParameter from '../../Hooks/Parameters/useReadParameter';

export const StateContext = createContext();

const StateContextProvider = ({children}) => {

    const { t } = useTranslation();
    const [ isLoading, setIsLoading ] = useState(false);

    // Search Activities Page
    const [ selectedSalesPersonActivities, setSelectedSalesPersonActivities ] = useState('');
    const [ searchTermActivities, setSearchTermActivities ] = useState('');
    const [ searchMyOnlyActivities, setSearchMyOnlyActivities ] = useState(true);
    const [ searchBySalesPersonActivities, setSearchBySalesPersonActivities ]= useState(false);

    // Search Contacts Page
    const [ searchContactsTerm, setSearchContactsTerm ] = useState('');

    // Search Notification Page
    const [ searchNotificationsTerm, setSearchNotificationsTerm ] = useState('');
    const [ upcomingNotificationsOnly, setUpcomingNotificationsOnly ] = useState(true);  

    // Search Projects Page
    const [ searchTermProjects, setSearchTermProjects ] = useState('');
    const [ selectedSalesPersonProjects, setSelectedSalesPersonProjects ] = useState('');
    const [ filterMyOnlyCheckboxProjects, setFilterMyOnlyCheckboxProjects ] = useState(true);
    const [ filterBySalesPersonCheckboxProjects, setFilterBySalesPersonCheckboxProjects ] = useState(false);
    const [ searchByCategoryRadioProjects, setSearchByCategoryRadioProjects ] = useState('project');
    const [ selectedStatusRadioGroupProjects, setSelectedStatusRadioGroupProjects ] = useState('active');
    const [ selectedStatusFiltersProjects, setSelectedStatusFiltersProjects ] = useState([]);
    
    // Search Project Products Page
    const [ searchTermProjectProducts, setSearchTermProjectProducts ] = useState('');
    const [ selectedSalesPersonProjectProducts, setSelectedSalesPersonProjectProducts ] = useState('');
    const [ selectedBusinessUnitProjectProducts, setSelectedBusinessUnitProjectProducts ] = useState(0)
    const [ filterByStatusRadioProjectProducts, setFilterByStatusRadioProjectProducts ] = useState('active');
    const [ filterMyOnlyCheckboxProjectProducts, setProjectProductsFilterMyOnlyCheckbox ] = useState(true);
    const [ filterBySalesPersonCheckboxProjectProduct, setFilterBySalesPersonCheckboxProjectProduct ] = useState(false);
    const [ selectedStatusFiltersProjectProducts, setSelectedStatusFiltersProjectProducts ] = useState([]);

    // Business partner / summary by customer Page
    const [ selectedCustomer, setSelectedCustomer ] = useState(null);
    const [ myOwnCustomersOnly, setMyOwnCustomersOnly ] = useState(true);
    const [ activeCustomersOnly, setActiveCustomersOnly ] = useState(true);

    // Customer hooks
    const { statusCodes, listStatusCodes } = useListStatusCodes([]);
    const { businessUnits , listBusinessUnits } = useListBusinessUnits([]);
    const { activityTypes, listActivityTypes } = useListActivityTypes([]);
    const { verifiedUserProfile, verifyAuthenticatedUser } = useVerifyAuthenticatedUser();
    const { parameter, readParameter } = useReadParameter();
    //const { read } = useTestApi();

    /** This Effect is user to read and verify users permission and groups in Action Arena 3 app.
     * This must be used in the top of the app hierarchy but below the MS MSAL MsalAuthenticationTemplate.
     * User roles and permission are verified once after the user logs in or refreshes the app.
     * useVerifyAuthenticated user hook is used to do this.
     * VerifyAuthenticated API endpoint fetch users AAD groups, updated the permission in Action Arena app and return user profile. */
    useEffect(() => {
        (async () => {

            setIsLoading(true);
           
            await verifyAuthenticatedUser();
            await listStatusCodes();
            await listBusinessUnits();
            await listActivityTypes();
            await readParameter(12); //"Read UiMessage"

            /** Test API and get accessToken if you need to test API using postman. 
             * Copy token from the console and add to 'authorization: Bearer <token>' header! ### */
            // await read();

            setIsLoading(false);

        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Get portal update message from parameters if defined.
    let portalUpdate = parameter && 
               parameter.Id &&
               parameter.Id === 12 &&
               parameter.PValue &&
               parameter.PValue.length
        ? JSON.parse(parameter.PValue)
        : null;

    /** Public properties and methods
     * @description Add all properties and method here which should be accessible to all components and pages. **/ 
    const globalState = {

        // General
        portalUpdate: portalUpdate,
        userProfile: verifiedUserProfile,
        statusCodes: statusCodes,
        businessUnits: businessUnits,
        activityTypes: activityTypes,

        // Activities Search
        searchTermActivities: searchTermActivities,
        setSearchTermActivities: setSearchTermActivities,
        searchMyOnlyActivities: searchMyOnlyActivities,
        setSearchMyOnlyActivities: setSearchMyOnlyActivities,
        searchBySalesPersonActivities: searchBySalesPersonActivities,
        setSearchBySalesPersonActivities: setSearchBySalesPersonActivities,
        selectedSalesPersonActivities: selectedSalesPersonActivities,
        setSelectedSalesPersonActivities: setSelectedSalesPersonActivities,  

        // Contacts
        searchContactsTerm: searchContactsTerm,
        setSearchContactsTerm: setSearchContactsTerm,

        // Notifications
        searchNotificationsTerm: searchNotificationsTerm,
        setSearchNotificationsTerm: setSearchNotificationsTerm,
        upcomingNotificationsOnly: upcomingNotificationsOnly,
        setUpcomingNotificationsOnly: setUpcomingNotificationsOnly,

        // Projects
        searchTermProjects: searchTermProjects,
        setSearchTermProjects: setSearchTermProjects,
        selectedSalesPersonProjects: selectedSalesPersonProjects,
        setSelectedSalesPersonProjects: setSelectedSalesPersonProjects,
        filterMyOnlyCheckboxProjects: filterMyOnlyCheckboxProjects,
        setFilterMyOnlyCheckboxProjects: setFilterMyOnlyCheckboxProjects,
        filterBySalesPersonCheckboxProjects: filterBySalesPersonCheckboxProjects,
        setFilterBySalesPersonCheckboxProjects: setFilterBySalesPersonCheckboxProjects,
        searchByCategoryRadioProjects: searchByCategoryRadioProjects,
        setSearchByCategoryRadioProjects: setSearchByCategoryRadioProjects,
        selectedStatusRadioGroupProjects: selectedStatusRadioGroupProjects,
        setSelectedStatusRadioGroupProjects: setSelectedStatusRadioGroupProjects,
        selectedStatusFiltersProjects: selectedStatusFiltersProjects,
        setSelectedStatusFiltersProjects: setSelectedStatusFiltersProjects,

        // Project Products
        searchTermProjectProducts: searchTermProjectProducts,
        setSearchTermProjectProducts: setSearchTermProjectProducts,
        selectedSalesPersonProjectProducts: selectedSalesPersonProjectProducts,
        setSelectedSalesPersonProjectProducts: setSelectedSalesPersonProjectProducts,
        selectedBusinessUnitProjectProducts: selectedBusinessUnitProjectProducts,
        setSelectedBusinessUnitProjectProducts: setSelectedBusinessUnitProjectProducts,
        filterByStatusRadioProjectProducts: filterByStatusRadioProjectProducts,
        setFilterByStatusRadioProjectProducts: setFilterByStatusRadioProjectProducts,
        filterMyOnlyCheckboxProjectProducts: filterMyOnlyCheckboxProjectProducts,
        setProjectProductsFilterMyOnlyCheckbox: setProjectProductsFilterMyOnlyCheckbox,
        filterBySalesPersonCheckboxProjectProduct: filterBySalesPersonCheckboxProjectProduct,
        setFilterBySalesPersonCheckboxProjectProduct: setFilterBySalesPersonCheckboxProjectProduct,
        selectedStatusFiltersProjectProducts: selectedStatusFiltersProjectProducts,
        setSelectedStatusFiltersProjectProducts: setSelectedStatusFiltersProjectProducts,
        
        // Business partner selection (FROM [Customer page] TO [Contact search, project search and notification search])
        selectedCustomer: selectedCustomer,
        setSelectedCustomer: setSelectedCustomer,
        myOwnCustomersOnly: myOwnCustomersOnly,
        setMyOwnCustomersOnly: setMyOwnCustomersOnly,
        activeCustomersOnly: activeCustomersOnly,
        setActiveCustomersOnly: setActiveCustomersOnly

    }

    return (
        <StateContext.Provider value={{...globalState}}>
            <Loading active={ isLoading } text={t('general.loading')} />
            {children}
        </StateContext.Provider>
    );

}

export default StateContextProvider;
